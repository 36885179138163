import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import WidthWrapper from "../../components/widthWrapper"
import logoPath from "../../images/logos/logo.svg"
import twitterIconPath from "../../images/twitterIcon.svg"
import linkedInIconPath from "../../images/linkedInIcon.svg"
import facebookIconPath from "../../images/facebookIcon.svg"
import HideOnMobile from "../../components/hideOnMobile"
import config from "../../config"

const Section = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  background-color: ${props => (props.isBlueTheme ? "#117BF3" : "#0f1722")};
  @media only screen and (max-width: 992px) {
    background-color: #0f1722;
  }
  user-select: none;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
  a.icon-app {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    margin: ${props => (props.isBlueTheme ? "43px 0 43px" : "43px 0 0 0")};
  }
  max-width: 346px;
  justify-content: space-between;
  margin: 68px 0 41px 0;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 211px;
`

const Logo = styled.img`
  margin-right: 48px;
  cursor: pointer;

  @media only screen and (max-width: 992px) {
    margin-bottom: 40px;
    margin-right: 0;
  }
`

const Icon = styled.img`
  cursor: pointer;
  @media only screen and (max-width: 992px) {
    margin-bottom: 15px;
  }
`

const ColumnHeading = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.isBlueTheme ? "15" : "11")}px;
  line-height: 13px;
  color: ${props => (props.isBlueTheme ? "#FFFFFF" : "#8c9bb3")};
  margin-bottom: 33px;
`

const FooterLink = styled(Link)`
  text-decoration: none;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 22px;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    color: #8fc4ff;
  }
`

const FooterLinkExternal = FooterLink.withComponent("a")

const CopyRights = styled(ColumnHeading)`
  margin-bottom: 70px;
  margin-top: 40px;
  @media only screen and (max-width: 992px) {
    display: ${props => (props.isBlueTheme ? "none" : "")};
  }
`

const ContactStyled = styled.div`
  display: none;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #0f1722;
  margin: 19px 0px 15px;
  span {
    color: #107af1;
  }
  @media only screen and (max-width: 992px) {
    display: block;
  }
`

// Pricing - /plans
// Blog - /blog
// Terms & Conditions - /terms
// Privacy policy - /privacy
// About us - /about
// Contact us - /contact
// Help Center - https://support.airmason.com
// Affiliate program - https://partner.airmason.com/

const footer = props => {
  // eslint-disable-next-line react/prop-types
  const { isBlueTheme, isHomePage } = props

  return (
    <React.Fragment>
      <Section isBlueTheme={isBlueTheme} data-loadable-component="footer">
        <WidthWrapper isHomePage={isHomePage}>
          <Container>
            <Row>
              <LogoWrapper
                isBlueTheme={isBlueTheme}
                className={`${isHomePage && "w-full"}`}
              >
                <Link to="/" className={`${isHomePage && "text-center"}`}>
                  <Logo data-src={logoPath} isHomePage={isHomePage} />
                </Link>
                <Row className={`${isHomePage && "justify-center"}`}>
                  <a
                    href="https://twitter.com/getairmason"
                    target="_black"
                    rel="noopener"
                    className="icon-app"
                  >
                    <Icon data-src={twitterIconPath} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/airmason/"
                    target="_black"
                    rel="noopener"
                    className="icon-app"
                  >
                    <Icon data-src={linkedInIconPath} />
                  </a>
                  <a
                    href="https://www.facebook.com/TeamAirMason"
                    target="_black"
                    rel="noopener"
                    className="icon-app"
                  >
                    <Icon data-src={facebookIconPath} />
                  </a>
                </Row>
              </LogoWrapper>
            </Row>
            <HideOnMobile>
              <Row>
                <Column>
                  <ColumnHeading isBlueTheme={isBlueTheme}>
                    AIRMASON
                  </ColumnHeading>
                  <FooterLink to="/">Home</FooterLink>
                  <FooterLink to="/plans">Pricing</FooterLink>
                  <FooterLinkExternal
                    href="https://www.airmason.com/blog/"
                    target="_blank"
                    rel="noopener"
                  >
                    Blog
                  </FooterLinkExternal>

                  {/* <FooterLink to="/blog">Blog</FooterLink> */}
                  <FooterLink to="/terms-and-conditions">
                    Terms &amp; Conditions
                  </FooterLink>
                  <FooterLink to="/privacy">Privacy Policy</FooterLink>
                  <FooterLink to="/data-processing">Data Processing</FooterLink>
                  <FooterLink to="/security">Security</FooterLink>
                  <FooterLink to="/advocacyterms">
                    Advocacy & Referral
                  </FooterLink>
                  {/* <FooterLink to="/privacy">Privacy Policy</FooterLink> */}
                </Column>
                <Column>
                  <ColumnHeading isBlueTheme={isBlueTheme}>
                    COMPANY
                  </ColumnHeading>

                  <FooterLink to="/about">About Us</FooterLink>

                  <FooterLink to="/contact">Contact Us</FooterLink>

                  <FooterLinkExternal
                    href={`${config.SUPPORT_CENTER_URL}`}
                    target="_blank"
                    rel="noopener"
                  >
                    Help Center
                  </FooterLinkExternal>

                  <FooterLinkExternal
                    href="https://heap.io/?utm_source=badge"
                    rel="nofollow"
                    target="_blank"
                  >
                    {/* <img
                      style={{ width: "108px", height: "41px" }}
                      data-src="//heapanalytics.com/img/badge.png"
                      // src="//heapanalytics.com/img/badge.png"
                      alt="Heap | Mobile and Web Analytics"
                    /> */}
                  </FooterLinkExternal>
                </Column>
                {/* <Column>
                  <ColumnHeading>INDUSTRIES</ColumnHeading>
                  <FooterLink>Case Studies</FooterLink>
                  <FooterLink>Small Business</FooterLink>
                  <FooterLink>Digital Agencies</FooterLink>
                  <FooterLink>Restaurants</FooterLink>
                  <FooterLink>Retail Stores</FooterLink>
                </Column>  */}
              </Row>
            </HideOnMobile>
            <Row className={`${isHomePage && "justify-center"}`}>
              <CopyRights isBlueTheme={isBlueTheme}>
                © 2023 — AirMason. All rights reserved
              </CopyRights>
            </Row>
          </Container>
        </WidthWrapper>
      </Section>
      {isBlueTheme && (
        <WidthWrapper>
          <ContactStyled>
            Contact Sales
            <span> +1 (646) 918-8673</span>
            ,
            <br />
            or email
            <span> hello@airmason.com</span>
          </ContactStyled>
        </WidthWrapper>
      )}
    </React.Fragment>
  )
}

export default footer
